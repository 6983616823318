import React, { useContext, useState, useEffect } from 'react'
import { Map } from 'immutable'
import { DialogContent, Button, Tooltip, FormControl, FormControlLabel, RadioGroup, Radio, Grid, Dialog, DialogActions } from '@material-ui/core'
import Context from 'reactContext'
import GiftCard from 'components/giftcard'
import Agent from 'components/agent'
import { get, post } from 'utils/request'

const Cards = () => {
  const { selfSetIn } = useContext(Context)
  const [amount, setAmount] = useState(300)
  const [visible, setVisible] = useState(false)
  const [cardUsers, setCardUsers] = useState([])
  const [giftCards, setGiftCards] = useState([])

  const getUsers = async () => {
    const res = await get('agents/gift_cards/managers')
    if (res.ok) {
      setCardUsers(res.managers)
      setGiftCards(res.giftCards)
    }
  }

  const create = async (params) => {
    const res = await post('agents/gift_cards', params)
    const { ok, gift_card: giftCard, card_number: cardNumber } = res
    if (ok) {
      selfSetIn(['gift_cards', cardNumber], giftCard)
    }
  }

  const gifts = Map(giftCards).sortBy((s) => s.manager_id === null && s.amount)

  const getGifts = gifts.toJS()

  const quickChargePanel = () => {
    return (
      <div className="text-center">
        <div className="tex-left mb-3">请选择金额</div>
        <FormControl>
          <RadioGroup row value={amount}>
            {[300, 500, 1000, 2000, 3000, 5000].map((v) => (
              <FormControlLabel key={v} value={v} onChange={() => setAmount(v)} control={<Radio />} label={`¥${v}`} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <Agent>
      <div className="mb-6">
        <Grid container>
          {Object.entries(getGifts).map((card, cardNumber) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={String(cardNumber)}>
              <GiftCard cardNumber={card[0]} managerId={card[1].manager_id} user={Map(cardUsers[card[1].manager_id])} amount={card[1].amount} />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className="mb-6 flip-container text-center m-auto">
              <div className="bg-add front add-card">
                <Tooltip title="添加卡片" placement="top">
                  <i className="iconfont icon-append cursor-pointer text-5xl text-white" role="presentation" onClick={() => setVisible(true)} />
                </Tooltip>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogContent>{quickChargePanel()}</DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            取消
          </Button>
          <Button
            onClick={() => {
              create({ amount })
              setVisible(false)
            }}
            color="primary"
          >
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </Agent>
  )
}

export default Cards
