import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SnackMessage from 'components/snackbar'
import { getRoot, getDay } from 'utils'

export default ({ user, managerId, amount, cardNumber }) => {
  const isUser = managerId == null
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const name = user.getIn(['settings', 'name'])
  const balance = `余额：¥ ${user.get('balance', 0) / 100}`
  const created = `日期：${getDay(user.get('created_at'))}`
  const text = (
    <span className={`float-right mr-6 inline-block text-center w-24 ${isUser ? 'mt-12' : 'mt-6'}`}>
      {isUser ? (
        <div>
          <span className="align-middle inline-block pb-4 mb-6">¥</span> <span className="card-amount font-bold">{`${amount / 100}`}</span>
        </div>
      ) : (
        <div className="text-white text-left text-sm">
          <div>{balance}</div>
          <div>{`首充：¥ ${amount / 100}`}</div>
          <div>{created}</div>
        </div>
      )}
    </span>
  )
  const unused = (
    <span className="float-right mr-6 inline-block text-center w-16 mt-12">
      {isUser ? (
        <div>
          <span className="card-unused font-bold">未使用</span>
        </div>
      ) : (
        <div className="texxt-left text-white text-sm">
          <div>{balance}</div>
          <div>{`首充：¥ ${amount / 100}`}</div>
          <div>{created}</div>
        </div>
      )}
    </span>
  )
  const front = (
    <div className={`front ${isUser ? 'rectangle' : 'card-clear'}`}>
      {text}
      <div>{isUser ? null : <div className="enterprise-name">{name}</div>}</div>
    </div>
  )
  if (!isUser) {
    return <div className="flip-container mb-6 texxt-center m-auto">{front}</div>
  }
  const signupUrl = `${getRoot()}/signup?gift_card_number=${cardNumber}`

  return (
    <div>
      <div className="mb-6 text-center m-auto flip-container">
        <div className="flipper">
          {front}
          <div className="rectangle back">
            {unused}
            <div className="card-left">
              <div className="qrcode-card">
                <QRCode value={signupUrl} size={70} className="back-image" />
              </div>
              <CopyToClipboard
                text={signupUrl}
                onCopy={() => {
                  setOpen(true)
                  setResMessage('注册链接已复制')
                }}
              >
                <img src="/arrow.png" alt="" className="card-next-back" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="success" />
    </div>
  )
}
